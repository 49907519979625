var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{ref:"container"},[_c('a-affix',{attrs:{"offset-top":0}},[_c('a-page-header',{staticClass:"mb-5 pr-0",staticStyle:{"border-bottom":"1px solid rgb(235, 237, 240)","background":"#fff"},attrs:{"title":"Edit Voucher"},on:{"back":() => {
            delete _vm.$route.query.uuid;
            
            _vm.$router.push({
              name: 'promotion-channel.web-voucher',
              query: _vm.$route.query,
            })
          }}})],1)],1),_c('VoucherForm',{attrs:{"value":_vm.data,"status-voucher":_vm.statusVoucher}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }