<template>
  <div>
    <div ref="container">
      <a-affix :offset-top="0">
        <a-page-header
          style="border-bottom: 1px solid rgb(235, 237, 240); background: #fff"
          class="mb-5 pr-0"
          title="Edit Voucher"
          @back="
            () => {
              delete $route.query.uuid;
              
              $router.push({
                name: 'promotion-channel.web-voucher',
                query: $route.query,
              })
            }
          "
        />
      </a-affix>
    </div>
    <VoucherForm :value="data" :status-voucher="statusVoucher" />
  </div>
</template>

<script>
import VoucherForm from './components/Form'

export default {
  components: {
    VoucherForm,
  },
  data() {
    return {
      data: {},
      statusVoucher: '',
    }
  },
  mounted() {
    this.$store
      .dispatch('web_platform_channel/discounts/GETVOUCHERDETAIL', {
        ...this.$route.params,
        ...this.$route.query,
      })
      .then(({ data }) => {
        this.data = data
        this.statusVoucher = this.checkStatusVoucher(
          data.validity.from,
          data.validity.until,
        )
      })
  },
  methods: {
    changeDateFormat(value) {
      return this.$moment.unix(value).format('YYYY-MM-DD HH:mm:ss')
    },

    checkStatusVoucher(from, until) {
      /** Check expired */
      if (this.$moment(this.changeDateFormat(until)).isBefore(this.$moment())) {
        return 'expired'
      }
      /** Check Coming Soon */
      if (this.$moment(this.changeDateFormat(from)).isAfter(this.$moment())) {
        return 'coming soon'
      }
      /** Check Running Voucher */
      if (
        this.$moment().isBetween(
          this.changeDateFormat(from),
          this.changeDateFormat(until),
        )
      ) {
        return 'running'
      }
    },
  },
}
</script>
